import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import { Paper, List, ListItem, CircularProgress, Dialog } from '@material-ui/core';
import { Dropdown } from 'antd';
import { Loading } from 'components';
import { profileAction } from 'actions/profile';
import { coreuiAction } from 'actions/coreui';
import { queryClient } from 'config';
import { parse } from 'query-string';
import { NETWORK_CHAIN_IDS } from 'env';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

const ethAssets = {
  icon: <img src={require('assets/icons/coins/coin-ethereum.png').default} />,
  backgroundIcon: <img src={require('assets/icons/coins/coin-ethereum-white.svg').default} height={24} />,
  backgroundImage: require('assets/icons/coins/coin-ethereum-frame.png').default,
  color: '#FFF',
};

const bscAssets = {
  icon: <img src={require('assets/icons/coins/coin-binance.png').default} />,
  backgroundIcon: <img src={require('assets/icons/coins/coin-binance-black.svg').default} height={24} />,
  backgroundImage: require('assets/icons/coins/coin-binance-frame.png').default,
  color: '#242424',
};

export const NETWORK_BARS = [
  {
    ...ethAssets,
    name: 'Ethereum Mainnet',
    chainId: '0x1',
    viewTrans: (txHash) => `https://etherscan.io/tx/${txHash}`,
    viewTokenID: (address, tokenID) => `https://etherscan.io/token/${address}?a=${tokenID}`,
  },
  {
    ...ethAssets,
    name: 'Ethereum Rinkeby',
    chainId: '0x4',
    viewTrans: (txHash) => `https://rinkeby.etherscan.io/tx/${txHash}`,
    viewTokenID: (address, tokenID) => `https://rinkeby.etherscan.io/token/${address}?a=${tokenID}`,
  },
  {
    ...ethAssets,
    name: 'Ethereum Goerli',
    chainId: '0x5',
    viewTrans: (txHash) => `https://goerli.etherscan.io/tx/${txHash}`,
    viewTokenID: (address, tokenID) => `https://goerli.etherscan.io/token/${address}?a=${tokenID}`,
  },
  {
    ...bscAssets,
    name: 'Binance Smart Chain',
    chainId: '0x38',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    viewTrans: (txHash) => `https://bscscan.com/tx/${txHash}`,
    viewTokenID: (address, tokenID) => `https://bscscan.com/token/${address}?a=${tokenID}`,
  },
  {
    ...bscAssets,
    name: 'Binance Smart Chain',
    chainId: '0x61',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    viewTrans: (txHash) => `https://testnet.bscscan.com/tx/${txHash}`,
    viewTokenID: (address, tokenID) => `https://testnet.bscscan.com/token/${address}?a=${tokenID}`,
  },
].filter((item) => NETWORK_CHAIN_IDS.split('_').includes(item.chainId));

const isMatchNetwork = (chainId) => NETWORK_BARS.some((item) => item.chainId === chainId);

const NetworkBar = ({ visible }) => {
  const location = useLocation();
  const { chainId: nextId = '' } = parse(location.search);
  const { t } = useTranslation();
  const { chain, network } = useSelector(({ coreui }) => coreui);

  const [chainId, setChainId] = React.useState(nextId || NETWORK_BARS[0].chainId);

  const handleChangeChainId = async (item) => {
    if (!window.ethereum) {
      return profileAction.requireMetaMask();
    }

    try {
      if (item.chainId !== window.ethereum.chainId)
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: item.chainId }],
        });
      else {
        setChainId(item.chainId);
      }
    } catch (error) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: item.chainId,
              chainName: item.name,
              rpcUrls: item.rpcUrls,
              blockExplorerUrls: item.blockExplorerUrls,
              nativeCurrency: item.nativeCurrency,
            },
          ],
        });
      }
    }
  };

  React.useEffect(() => {
    if (isMatchNetwork(chainId)) {
      const chain = NETWORK_BARS.find((item) => item.chainId === chainId);
      queryClient.invalidateQueries();
      coreuiAction.updateChain({
        chainId: chain.chainId,
        viewTrans: chain.viewTrans,
        viewTokenID: chain.viewTokenID,
      });
      profileAction.fetchProfile();
    }
  }, [chainId]);

  React.useEffect(() => {
    window.ethereum?.on('chainChanged', (chainId) => {
      setChainId(chainId);
      coreuiAction.updateNetwork({ isOpenSwitch: false });
    });
  }, []);

  const networkChoose = NETWORK_BARS.find((item) => item.chainId === chainId) || {
    icon: (
      <Loading
        size={16}
        style={{ margin: '0 10px 0 2px' }}
        icon={<BlockOutlinedIcon color='error' style={{ width: 20, margin: '0 8px' }} />}
      />
    ),
    name: 'Wrong network',
  };

  return (
    <>
      <Dropdown
        trigger='click'
        getPopupContainer={(event) => event.parentNode}
        overlay={
          <div>
            <List disablePadding component={Paper}>
              {NETWORK_BARS.map((item) => (
                <ListItem
                  button
                  key={item.chainId}
                  selected={item.chainId === networkChoose.chainId}
                  onClick={() => handleChangeChainId(item)}
                >
                  {item.icon} {t(item.name)}
                </ListItem>
              ))}
            </List>
          </div>
        }
      >
        <Button
          variant='outlined'
          style={visible ? { whiteSpace: 'nowrap' } : { display: 'none' }}
          endIcon={<ArrowDropDownOutlinedIcon />}
        >
          {networkChoose.icon} {t(networkChoose.name)}
        </Button>
      </Dropdown>

      <Dialog open={Boolean(chainId) && !isMatchNetwork(chainId)} PaperProps={{ style: { width: 360 } }}>
        <Box className='flex-column align-items-center'>
          <CircularProgress className='mb-12' />
          <Typography variant='h5'>{t('Wrong network')}</Typography>
          <Typography color='textSecondary' paragraph>
            {t('Change network to')}
          </Typography>
          <Box className='flex-column'>
            {NETWORK_BARS.map((item) => (
              <Button
                key={item.chainId}
                onClick={() => handleChangeChainId(item)}
                className='mb-12 py-12 px-24'
                style={{ backgroundImage: `url(${item.backgroundImage})`, color: item.color }}
                startIcon={item.backgroundIcon}
              >
                {t(item.name)}
              </Button>
            ))}
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={network.isOpenSwitch}
        onClose={() => coreuiAction.updateNetwork({ isOpenSwitch: false })}
        PaperProps={{ style: { width: 360 } }}
      >
        <Box className='flex-column align-items-center'>
          <CircularProgress className='mb-12' />
          <Typography variant='h5'>{t('Wrong network')}</Typography>
          <Typography color='textSecondary' paragraph>
            {t('Change network to')}
          </Typography>
          <Box className='flex-column'>
            {NETWORK_BARS.filter((item) => item.chainId === chain.chainId).map((item) => (
              <Button
                key={item.chainId}
                onClick={() => handleChangeChainId(item)}
                className='mb-12 py-12 px-24'
                style={{ backgroundImage: `url(${item.backgroundImage})`, color: item.color }}
                startIcon={item.backgroundIcon}
              >
                {t(item.name)}
              </Button>
            ))}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default NetworkBar;
