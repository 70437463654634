import { client } from './axios';

const upload = (file) =>
  client.get(`/v1/nft/medias/presign-upload`).then(({ url }) =>
    fetch(url, {
      method: 'PUT',
      headers: { 'x-amz-acl': 'public-read' },
      body: file,
    }).then(() => {
      const { origin, pathname } = new URL(url);
      return origin + pathname;
    }),
  );

export const fileService = {
  upload,
};
