import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Hidden, IconButton, Typography, Tooltip } from '@material-ui/core';
import { Col, Row, Image } from 'antd';
import { ColorButton, Loading } from 'components';
import { marketContract, erc20Contract, MAX_INT } from 'contracts';
import { marketService } from 'services/market';
import { onCatchError } from 'utils/common';
import { LineItem } from './PopupCreateSale';

import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';

const PopupBuy = ({ isAuction, item, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);
  const { saleInfo } = item;
  if (isAuction) saleInfo.price = item.highestBid.offerPrice;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenExchange, setIsOpenExchange] = React.useState(false);

  const [balance, setBalance] = React.useState();

  const fetchBalance = React.useCallback(async () => {
    let balance = 0;
    try {
      const wei = await erc20Contract(saleInfo.paymentToken.address).methods.balanceOf(user).call();
      balance = wei / 10 ** saleInfo.paymentToken.decimals;
    } catch {}
    setBalance(balance);
    return balance;
  }, [saleInfo, user]);

  const handleClickConfirm = async () => {
    if (saleInfo.price > (await fetchBalance())) return;

    setIsLoading(true);
    try {
      const callApi = isAuction ? marketService.confirmBid : marketService.buy;
      const confirm = await callApi({ saleId: saleInfo.id, bidId: item.highestBid.id });

      const buyData = {
        components: Object.values({
          seller: confirm.seller,
          paymentReceiver: confirm.sellerReceiveAddress,
          contractNFT: confirm.nftContract,
          paymentToken: confirm.paymentTokenAddr,
          tokenId: confirm.tokenID,
          feeRate: confirm.feeRate,
          price: confirm.price,
          amount: confirm.amount,
          sellId: confirm.rawSaleID,
        }),
        signature: confirm.signature,
      };

      const allowance = await erc20Contract(confirm.paymentTokenAddr)
        .methods.allowance(user, confirm.marketContractAddr)
        .call();

      if (Number(allowance) < Number(confirm.price)) {
        await erc20Contract(confirm.paymentTokenAddr)
          .methods.approve(confirm.marketContractAddr, MAX_INT)
          .send({ from: user });
      }

      if (confirm.purchaseMethod === 0) {
        await marketContract(confirm.marketContractAddr)
          .methods.buyNFT721NativeCoin(...Object.values(buyData))
          .send({ from: user });
      }
      if (confirm.purchaseMethod === 1) {
        await marketContract(confirm.marketContractAddr)
          .methods.buyNFT1155NativeCoin(...Object.values(buyData))
          .send({ from: user });
      }
      if (confirm.purchaseMethod === 2) {
        await marketContract(confirm.marketContractAddr)
          .methods.buyNFT721ERC20(...Object.values(buyData))
          .send({ from: user });
      }
      if (confirm.purchaseMethod === 3) {
        await marketContract(confirm.marketContractAddr)
          .methods.buyNFT1155ERC20(...Object.values(buyData))
          .send({ from: user });
      }
      onSuccess();
    } catch (error) {
      onCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <>
      <Row hidden={isOpenExchange} gutter={24} wrap={false}>
        <Hidden smDown>
          <Col style={{ minHeight: 360, width: 360 }} className='flex-center'>
            {item.isVideo ? (
              <video src={item.image} style={{ maxHeight: 480, minWidth: 120 }} controls autoPlay loop muted />
            ) : (
              <Image src={item.image} style={{ maxHeight: 480, minWidth: 120 }} preview={false} />
            )}
          </Col>
        </Hidden>
        <Col flex={1} className='flex-column justify-content-between'>
          <Box mb={5}>
            <Box className='justify-content-between align-items-center mb-16'>
              <Typography variant='h5'>{isAuction ? t('Claim') : t('Purchase')}</Typography>
              <IconButton onClick={onClose} disabled={isLoading}>
                <CloseIcon />
              </IconButton>
            </Box>

            <LineItem label={t('Price')} author={`${saleInfo.price} ${saleInfo.paymentToken?.symbol}`} />

            <LineItem label={t('Service fee')} author={t('Free')} />

            <Divider />
            <LineItem
              label={t('Total')}
              labelProps={{ variant: 'body1', color: 'textPrimary' }}
              author={`${saleInfo.price} ${saleInfo.paymentToken?.symbol}`}
              authorProps={{ variant: 'h6' }}
            />

            {saleInfo.price > balance && (
              <Insufficient onRefresh={fetchBalance} onExchange={() => setIsOpenExchange(true)} />
            )}
          </Box>

          <ColorButton
            fullWidth
            disabled={saleInfo.price > balance || isLoading}
            startIcon={<Loading visible={isLoading} />}
            onClick={handleClickConfirm}
            style={{ height: 52 }}
          >
            {isAuction ? t('CONFIRM') : t('BUY NOW')}
          </ColorButton>
        </Col>
      </Row>
      {isOpenExchange && (
        <ExchangeFrame
          outputCurrency={saleInfo.paymentToken.address}
          onBack={() => {
            setIsOpenExchange(false);
            fetchBalance();
          }}
        />
      )}
    </>
  );
};

export const Insufficient = ({ onRefresh, onExchange }) => {
  const { t } = useTranslation();
  return (
    <Box variant='body2' color='error' className='flex-row'>
      <Typography variant='body2' color='error'>
        {t('Your balance is insufficient.')}
      </Typography>
      <Tooltip title='Refresh'>
        <SyncIcon fontSize='small' style={{ cursor: 'pointer', marginLeft: 8 }} onClick={onRefresh} />
      </Tooltip>

      <Typography
        variant='subtitle2'
        color='secondary'
        className='cursor-pointer'
        style={{ cursor: 'pointer', marginLeft: 'auto' }}
        onClick={onExchange}
      >
        {t('Exchange')}
      </Typography>
    </Box>
  );
};

export const ExchangeFrame = ({ outputCurrency, onBack }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <iframe
        title='uniswap'
        src={`https://app.uniswap.org/#/swap?outputCurrency=${outputCurrency}`}
        style={{ width: '100%', height: 580, border: 0 }}
      />
      <Box className='justify-content-center'>
        <Button variant='contained' style={{ width: 240 }} onClick={onBack}>
          {t('BACK')}
        </Button>
      </Box>
    </Box>
  );
};

export default PopupBuy;
