import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Box, Container, Typography } from '@material-ui/core';
import { ButtonSelect } from 'components';
import { List } from 'antd';
import { marketService } from 'services/market';
import { moment } from 'utils/common';
import { CardUser } from 'views/Explore/components';

import IconTopProfile from 'assets/icons/listing/icon-top-profile.png';

const TIME_FILTERS = [
  { id: 1, code: 1, name: 'Last 1 day' },
  { id: 2, code: 7, name: 'Last 7 days' },
  { id: 3, code: 30, name: 'Last 30 days' },
];

const TopSeller = () => {
  const { t } = useTranslation();
  const { payments } = useSelector(({ system }) => system);

  const [paymentTokenID, setPaymentTokenID] = React.useState();
  const [dataSearch, setDataSearch] = React.useState({
    size: 10,
    fromDayCount: TIME_FILTERS[2].code,
    endTime: moment().endOf('days').unix(),
  });

  React.useEffect(() => {
    setPaymentTokenID(payments[0]?.id);
  }, [payments]);

  const isEnabled = !!paymentTokenID;
  const { data, isFetching } = useQuery(
    ['marketService.fetchTopSellers', { ...dataSearch, paymentTokenID }],
    ({ queryKey }) =>
      marketService
        .fetchTopSellers({
          ...queryKey[1],
          fromDayCount: undefined,
          startTime: moment().add(-queryKey[1].fromDayCount, 'days').startOf('days').unix(),
        })
        .then((sellers) => ({ sellers })),
    { enabled: isEnabled, staleTime: 0 },
  );

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      ...search,
      endTime: moment().endOf('days').unix(),
    }));
  }, []);

  return (
    <Container>
      <Box className='justify-content-between flex-wrap mb-20'>
        <Typography variant='h4' className='align-items-center'>
          <img src={IconTopProfile} className='mr-12' />
          {t('Top Sellers')}
        </Typography>

        <Box>
          <ButtonSelect
            className='mr-16'
            options={payments.map((item) => (
              <ButtonSelect.Option
                key={item.id}
                selected={item.id === paymentTokenID}
                onClick={() => setPaymentTokenID(item.id)}
              >
                {item.symbol}
              </ButtonSelect.Option>
            ))}
          >
            {payments.find((item) => item.id === paymentTokenID)?.symbol}
          </ButtonSelect>

          <ButtonSelect
            options={TIME_FILTERS.map((item) => (
              <ButtonSelect.Option
                key={item.id}
                selected={item.id === dataSearch.fromDayCount}
                onClick={() => handleChangeSearch({ fromDayCount: item.code })}
              >
                {t(item.name)}
              </ButtonSelect.Option>
            ))}
          >
            {t(TIME_FILTERS.find((item) => item.code === dataSearch.fromDayCount)?.name)}
          </ButtonSelect>
        </Box>
      </Box>

      {isEnabled && !isFetching ? (
        <List
          grid={{ gutter: [24, 8], column: 5, xs: 2, sm: 2, md: 2, lg: 3 }}
          dataSource={data.sellers}
          renderItem={(item, index) => (
            <List.Item>
              <CardUser user={item} index={index} />
            </List.Item>
          )}
        />
      ) : (
        <List
          grid={{ gutter: [24, 8], column: 5, xs: 2, sm: 2, md: 2, lg: 3 }}
          dataSource={Array.from({ length: 10 }).map(() => ({}))}
          renderItem={(item, index) => (
            <List.Item>
              <CardUser.Skeleton />
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default TopSeller;
