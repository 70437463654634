import { useTranslation } from 'react-i18next';
import { Box, Button, Link as NavLink, makeStyles } from '@material-ui/core';

const TopBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box position='relative' component={NavLink} href='https://www.superboomi.spores.app/'>
      <img src={require('assets/images/banner_boomi.png').default} style={{ width: '100%' }} />
      <NavLink href='https://launchpad.spores.app'>
        <Button variant='contained' className={classes.button + ' gradient-primary'}>
          {t('PARTICIPATE')}
        </Button>
      </NavLink>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    width: 160,
    fontSize: 16,
    position: 'absolute',
    top: '30%',
    left: 'calc(50% - 80px)',
    display: 'none',

    [theme.breakpoints.down('md')]: {
      top: '30%',
    },
  },
}));

export default TopBanner;
