import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Link as NavLink, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from 'antd';
import { privateRoute } from 'routes';

import LogoSporesSquare from 'assets/icons/spores/logo-spores-square.png';
import LogoSporesBlack from 'assets/icons/spores/logo-spores-black.svg';

const SOCIALS = [
  { name: 'Medium', url: 'https://spores.medium.com' },
  { name: 'Instagram', url: 'https://www.instagram.com/SporesNetwork' },
  { name: 'Pinterest', url: 'https://pinterest.com/SporesNetwork' },
  { name: 'Facebook', url: 'https://www.facebook.com/SporesNetwork' },
  { name: 'Telegram', url: 'https://t.me/sporeseng' },
  { name: 'Twitter', url: 'https://twitter.com/Spores_Network' },
  { name: 'Youtube', url: 'https://www.youtube.com/channel/UC9pgjF_aWprnVodvxO7OPkA' },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/company/SporesNetwork' },
];

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <img src={LogoSporesSquare} className={classes.logo} />
      <Container>
        <Row gutter={24}>
          <Col style={{ width: 320 }}>
            <img src={LogoSporesBlack} style={{ height: 60 }} />
          </Col>
          <Col flex={1}>
            <Typography variant='h6' gutterBottom>
              {t('Menu')}
            </Typography>
            <Box className='flex-column align-items-start'>
              <Link className={classes.link} to={privateRoute.explore.path}>
                <Typography gutterBottom>{t('Explore')}</Typography>
              </Link>
              <NavLink className={classes.link} href='https://launchpad.spores.app'>
                <Typography gutterBottom>{t('Launchpad')}</Typography>
              </NavLink>
              <NavLink className={classes.link} href='https://staking.spores.app'>
                <Typography gutterBottom>{t('Staking')}</Typography>
              </NavLink>
            </Box>
          </Col>
          <Col flex={1}>
            <Typography variant='h6' gutterBottom>
              {t('Community')}
            </Typography>
            <Box className='flex-column align-items-start'>
              <NavLink className={classes.link} href='https://spores.nolt.io'>
                <Typography gutterBottom>{t('Suggest a Feature')}</Typography>
              </NavLink>
            </Box>
          </Col>
          <Col flex={1}>
            <Typography variant='h6' gutterBottom>
              {t('Legal')}
            </Typography>
            <Box className='flex-column align-items-start'>
              <Link className={classes.link} to={privateRoute.termsOfUse.path}>
                <Typography gutterBottom>{t('Terms Of Use')}</Typography>
              </Link>
              <Link className={classes.link} to={privateRoute.privaryPolicy.path}>
                <Typography gutterBottom>{t('Privacy Policy')}</Typography>
              </Link>
              <Link className={classes.link} to={privateRoute.cookieStatement.path}>
                <Typography gutterBottom>{t('Cookie Statement')}</Typography>
              </Link>
            </Box>
          </Col>
          <Col style={{ width: 160 }}>
            <Typography variant='h6' gutterBottom>
              {t('Contact')}
            </Typography>
            <Row>
              {SOCIALS.map((item, index) => (
                <Col key={index} span={6}>
                  <IconButton size='small' component={NavLink} href={item.url} title={item.name}>
                    <img src={require(`assets/icons/socials/${item.name}.svg`).default} />
                  </IconButton>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: '32px 0px',
    position: 'relative',
    overflow: 'hidden',
  },
  logo: {
    position: 'absolute',
    top: 40,
    left: -40,
  },
  link: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.text.hint,
    },
  },
}));

export default Footer;
