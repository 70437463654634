import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { PrivateLayout } from 'layouts';
import { AppTheme, AppError } from 'containers';
import { QueryClientProvider } from 'react-query';
import { store } from 'reducers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { default as ScrollMemory } from 'react-router-scroll-memory';
import { default as MomentUtils } from '@date-io/moment';
import * as config from 'config';

export const browserHistory = createBrowserHistory();
config.run();

const App = () => {
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.href = 'https://www.superboomi.spores.app';
    } else {
      setIsReady(true);
    }
  }, []);

  return (
    isReady && (
      <ReduxProvider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppTheme>
            <QueryClientProvider client={config.queryClient}>
              <Router history={browserHistory}>
                <ScrollMemory />
                <AppError>
                  <Switch>
                    <Route path='/' component={PrivateLayout} />
                  </Switch>
                </AppError>
              </Router>
            </QueryClientProvider>
          </AppTheme>
        </MuiPickersUtilsProvider>
      </ReduxProvider>
    )
  );
};

export default App;
