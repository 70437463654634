import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Hidden, IconButton, Typography } from '@material-ui/core';
import { Col, Row, Image } from 'antd';
import { ColorButton, Loading } from 'components';
import { erc721Contract } from 'contracts';
import { marketService } from 'services/market';
import { DDMMYYYY_HHMM } from 'utils/constants';
import { onCatchError } from 'utils/common';

import CloseIcon from '@material-ui/icons/Close';

export const LineItem = ({ label, labelProps = {}, author, authorProps = {} }) => (
  <Box className='justify-content-between align-items-center my-8'>
    <Typography variant='body2' color='textSecondary' {...labelProps}>
      {label}
    </Typography>
    <Typography variant='body1' {...authorProps}>
      {author}
    </Typography>
  </Box>
);

const PopupCreateSale = ({ formValues: values, item = {}, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const { isVideo, isAuction } = values;
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickConfirm = async () => {
    setIsLoading(true);
    try {
      const isApprovedForAll = await erc721Contract(item.nftContractAddr)
        .methods.isApprovedForAll(user, item.marketContractAddr)
        .call();

      if (!isApprovedForAll)
        await erc721Contract(item.nftContractAddr)
          .methods.setApprovalForAll(item.marketContractAddr, true)
          .send({ from: user });

      const saleParams = {
        ownershipID: item.ownershipID,
        paymentTokenID: values.paymentTokenID,
        price: values.price,
        startTime: values.startTime?.unix(),
        endTime: values.endTime.unix(),
        saleType: isAuction ? 1 : 0,
        reservedPrice: values.reservedPrice || undefined,
      };
      await marketService.createSale(saleParams);
      onSuccess(item.id);
    } catch (error) {
      onCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row gutter={24} wrap={false}>
      <Hidden smDown>
        <Col style={{ minHeight: 360, width: 360 }} className='flex-center'>
          {item.isVideo || isVideo ? (
            <video src={item.image ?? values.image} style={{ maxHeight: 480, minWidth: 120 }} controls />
          ) : (
            <Image src={item.image ?? values.image} style={{ maxHeight: 480, minWidth: 120 }} preview={false} />
          )}
        </Col>
      </Hidden>
      <Col flex={1} className='flex-column justify-content-between'>
        <Box mb={5}>
          <Box className='justify-content-between align-items-center mb-16'>
            <Typography variant='h5'>{t('Put on market')}</Typography>
            <IconButton onClick={onClose} disabled={isLoading}>
              <CloseIcon />
            </IconButton>
          </Box>

          <LineItem label={t('Name')} author={item.name} />
          <LineItem label={t('Price')} author={`${values.price} ${values.paymentToken.symbol}`} />
          {isAuction && (
            <>
              <LineItem label={t('Reserved Price')} author={`${values.reservedPrice} ${values.paymentToken.symbol}`} />
              <Box height={12} />
              <LineItem label={t('Starting Date')} author={values.startTime.format(DDMMYYYY_HHMM)} />
              <LineItem label={t('Expiration Date')} author={values.endTime.format(DDMMYYYY_HHMM)} />
            </>
          )}
        </Box>

        <ColorButton
          fullWidth
          disabled={isLoading}
          startIcon={<Loading visible={isLoading} />}
          onClick={handleClickConfirm}
          style={{ height: 52 }}
        >
          {t('CONFIRM')}
        </ColorButton>
      </Col>
    </Row>
  );
};

export default PopupCreateSale;
