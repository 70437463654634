import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Avatar, Button, Box, IconButton, TextField, Typography } from '@material-ui/core';
import { Container, FormGroup, Paper, Dialog } from '@material-ui/core';
import { Image, message, Spin, Upload } from 'antd';
import { ColorButton, InputNumberFormat } from 'components';
import { coreuiAction } from 'actions/coreui';
import { fileService } from 'services/file';
import { color } from 'utils/constants';
import { onFormError } from 'utils/common';
import { privateRoute } from 'routes';
import { PopupCreateCollection } from './components';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

const CollectionCreate = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const uploadEl = React.useRef();
  const [imageLoading, setImageLoading] = React.useState(false);
  const [coverLoading, setCoverLoading] = React.useState(false);

  const [isOpenCreate, setIsOpenCreate] = React.useState(false);
  const [createValues, setCreateValues] = React.useState(false);

  const handleClickCreate = () => {
    if (chain.chainId !== window.ethereum?.chainId) {
      coreuiAction.updateNetwork({ isOpenSwitch: true });
      return;
    }
    handleSubmit((values) => {
      setCreateValues(values);
      setIsOpenCreate(true);
    }, onFormError)();
  };

  return (
    <Container maxWidth='sm'>
      <Paper className='p-24' elevation={1}>
        <Box className='flex-row align-items-center mb-24'>
          <IconButton hidden component={Link} to={privateRoute.createType.path}>
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant='h4'>{t('Create collection')}</Typography>
        </Box>
        <Box className='flex-row mb-24'>
          <Controller
            name='subCollectionImage'
            control={control}
            rules={{ required: true }}
            render={({ field: { value: image, onChange }, fieldState: { invalid } }) => (
              <Upload.Dragger
                accept='image/*'
                showUploadList={false}
                customRequest={async ({ file }) => {
                  if (file.size / 1024 / 1024 >= 5) {
                    message.error(t('Maximum image size is 5MB'));
                  } else if (!file.type.startsWith('image')) {
                    message.error(t('File type is not allowed'));
                  } else {
                    setImageLoading(true);
                    onChange(await fileService.upload(file));
                    setImageLoading(false);
                  }
                }}
                className='flex-center'
                height={100}
                style={{ width: 100, borderRadius: 50, borderColor: invalid ? color.error : undefined }}
              >
                <Spin spinning={imageLoading}>
                  <Box ref={uploadEl}>
                    {image ? <Avatar src={image} style={{ width: 100, height: 100 }} /> : <WallpaperIcon />}
                  </Box>
                </Spin>
              </Upload.Dragger>
            )}
          />
          <Box ml={2}>
            <Typography variant='h6'>{t('Thumbnail')}</Typography>
            <Typography variant='body2' color='textSecondary' gutterBottom>
              {t('We recommend an image of at least *size*. GIFs work too.', { size: '400 x 400px' })}
            </Typography>
            <Button variant='outlined' size='small' onClick={() => uploadEl.current.click()}>
              {t('Choose File')}
            </Button>
          </Box>
        </Box>

        <Box>
          <Typography variant='h6'>{t('Cover')}</Typography>
          <Typography variant='body2' color='textSecondary' gutterBottom>
            {t('We recommend an image of at least *size*. GIFs work too.', { size: '1392 x 280px' })}
          </Typography>
          <Controller
            name='cover'
            control={control}
            rules={{ required: true }}
            render={({ field: { value: image, onChange }, fieldState: { invalid } }) => (
              <Upload.Dragger
                accept='image/*'
                showUploadList={false}
                customRequest={async ({ file }) => {
                  if (file.size / 1024 / 1024 >= 5) {
                    message.error(t('Maximum image size is 5MB'));
                  } else if (!file.type.startsWith('image')) {
                    message.error(t('File type is not allowed'));
                  } else {
                    setCoverLoading(true);
                    onChange(await fileService.upload(file));
                    setCoverLoading(false);
                  }
                }}
                className='mb-24'
                height={140}
                style={{ borderColor: invalid ? color.error : undefined }}
              >
                <Spin spinning={coverLoading}>
                  {image ? (
                    <Image src={image} height={120} preview={false} />
                  ) : (
                    <Box className='flex-column align-items-center'>
                      <CropOriginalIcon color='action' style={{ fontSize: 40 }} />
                      <Typography variant='h6' color='textSecondary'>
                        {t('Drag & drop file')}
                      </Typography>
                      <Typography variant='subtitle1' color='textSecondary'>
                        {t('or browse media on your device')}
                      </Typography>
                    </Box>
                  )}
                </Spin>
              </Upload.Dragger>
            )}
          />
        </Box>

        <FormGroup>
          <Typography variant='h6' gutterBottom>
            {t('Information')}
          </Typography>
          <Controller
            name='collectionName'
            defaultValue=''
            control={control}
            rules={{
              validate: {
                required: (value) => value.trim() !== '' || t('Name cannot be empty'),
                minLength: (value) => value.trim().length >= 5 || t('Name is at least 5 characters'),
                maxLength: (value) => value.trim().length <= 50 || t('Name is at most 50 characters'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                required
                label={t('Name')}
                placeholder={t('Enter collection name')}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name='symbol'
            defaultValue=''
            control={control}
            rules={{
              validate: {
                required: (value) => value !== '' || t('Symbol cannot be empty'),
                pattern: (value) => /^\w+$/.test(value) || t('Symbol is not valid'),
                minLength: (value) => value.length >= 3 || t('Symbol is at least 3 characters'),
                maxLength: (value) => value.length <= 10 || t('Symbol is at most 10 characters'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                required
                fullWidth
                label={t('Symbol')}
                placeholder={t('Enter collection symbol')}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name='maxEdition'
            defaultValue=''
            control={control}
            rules={{
              validate: {
                required: (value) => (value && value > 0) || t('Total Supply is required'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                required
                fullWidth
                label={t('Total Supply')}
                placeholder={t('Total number of items that can be minted')}
                InputProps={{
                  inputComponent: InputNumberFormat,
                  inputProps: { decimalScale: 0 },
                }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name='description'
            defaultValue=''
            control={control}
            rules={{
              validate: {
                required: (value) => value.trim() !== '' || t('Description cannot be empty'),
                minLength: (value) => value.trim().length >= 5 || t('Description is at least 5 characters'),
                maxLength: (value) => value.trim().length <= 500 || t('Description is at most 500 characters'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                required
                multiline
                rows={3}
                label={t('Description')}
                placeholder={t('Spread some words about your collection')}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </FormGroup>

        <ColorButton fullWidth size='large' onClick={handleClickCreate} style={{ height: 52 }}>
          {t('Create collection')}
        </ColorButton>
      </Paper>

      <Dialog open={isOpenCreate} maxWidth='xs'>
        <PopupCreateCollection
          formValues={createValues}
          onSuccess={() => {
            setIsOpenCreate(false);
            history.push(privateRoute.create.url('with-contract'));
          }}
          onClose={() => setIsOpenCreate(false)}
        />
      </Dialog>
    </Container>
  );
};

export default CollectionCreate;
