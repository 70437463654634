import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Box, Divider, Hidden, IconButton, Typography } from '@material-ui/core';
import { FormGroup, TextField, InputAdornment } from '@material-ui/core';
import { Col, Row, Image } from 'antd';
import { InputNumberFormat, ColorButton, Loading } from 'components';
import { erc20Contract } from 'contracts';
import { marketService } from 'services/market';
import { LineItem } from './PopupCreateSale';
import { ExchangeFrame, Insufficient } from './PopupBuy';

import CloseIcon from '@material-ui/icons/Close';

const PopupBid = ({ item, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);
  const { saleInfo, highestBid } = item;

  const { control, handleSubmit, watch, formState } = useForm({ mode: 'onChange' });
  const { price } = watch();

  const [balance, setBalance] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenExchange, setIsOpenExchange] = React.useState(false);

  const fetchBalance = React.useCallback(async () => {
    let balance = 0;
    try {
      const wei = await erc20Contract(saleInfo.paymentToken.address).methods.balanceOf(user).call();
      balance = wei / 10 ** saleInfo.paymentToken.decimals;
    } catch {}
    setBalance(balance);
    return balance;
  }, [saleInfo, user]);

  const handleClickConfirm = () => {
    handleSubmit(async ({ price }) => {
      if (price > (await fetchBalance())) return;

      setIsLoading(true);
      marketService
        .bid({
          saleId: saleInfo.id,
          price,
          paymentTokenID: saleInfo.paymentTokenID,
          amount: saleInfo.amount,
        })
        .then(() => {
          onSuccess();
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  };

  React.useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <>
      <Row hidden={isOpenExchange} gutter={24} wrap={false}>
        <Hidden smDown>
          <Col style={{ minHeight: 360, width: 360 }} className='flex-center'>
            {item.isVideo ? (
              <video src={item.image} style={{ maxHeight: 480, minWidth: 120 }} controls autoPlay loop muted />
            ) : (
              <Image src={item.image} style={{ maxHeight: 480, minWidth: 120 }} preview={false} />
            )}
          </Col>
        </Hidden>
        <Col flex={1} className='flex-column justify-content-between'>
          <Box mb={5}>
            <Box className='justify-content-between align-items-center mb-16'>
              <Typography variant='h5'>{t('Place a bid')}</Typography>
              <IconButton onClick={onClose} disabled={isLoading}>
                <CloseIcon />
              </IconButton>
            </Box>

            <LineItem
              label={item.isAuctionBids ? t('Current bid') : t('Initial price')}
              author={`${highestBid.offerPrice} ${saleInfo.paymentToken.symbol}`}
            />

            <FormGroup>
              <Controller
                name='price'
                defaultValue=''
                control={control}
                rules={{
                  validate: {
                    required: (value) => (value && value > 0) || t('Place your bid'),
                    minValue: (value) =>
                      item.isAuctionBids
                        ? value > highestBid.offerPrice || t('Place your bid higher than current bid')
                        : value >= saleInfo.price || t('Place your bid higher than initial price'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    autoFocus
                    fullWidth
                    label={t('Your bid')}
                    style={{ margin: '4px 0px 24px' }}
                    InputProps={{
                      inputComponent: InputNumberFormat,
                      endAdornment: (
                        <InputAdornment position='end' disableTypography>
                          {saleInfo.paymentToken.symbol}
                        </InputAdornment>
                      ),
                    }}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </FormGroup>

            <LineItem label={t('Your bid')} author={`${price ?? 0} ${saleInfo.paymentToken.symbol}`} />
            <LineItem label={t('Service fee')} author={t('Free')} />

            <Divider />
            <LineItem
              label={t('Total')}
              labelProps={{ variant: 'body1', color: 'textPrimary' }}
              author={`${price ?? 0} ${saleInfo.paymentToken.symbol}`}
              authorProps={{ variant: 'h6' }}
            />

            {price > balance && <Insufficient onRefresh={fetchBalance} onExchange={() => setIsOpenExchange(true)} />}
          </Box>

          <ColorButton
            fullWidth
            disabled={price > balance || !!formState.errors.price || isLoading}
            startIcon={<Loading visible={isLoading} />}
            onClick={handleClickConfirm}
            style={{ height: 52 }}
          >
            {'BID NOW'}
          </ColorButton>
        </Col>
      </Row>
      {isOpenExchange && (
        <ExchangeFrame
          outputCurrency={saleInfo.paymentToken.address}
          onBack={() => {
            setIsOpenExchange(false);
            fetchBalance();
          }}
        />
      )}
    </>
  );
};

export default PopupBid;
