import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Container, Box, Divider, Dialog, Paper, Typography } from '@material-ui/core';
import { Tabs, Tab, IconButton, CardMedia, makeStyles } from '@material-ui/core';
import { BackgroundColor } from 'components';
import { Col, Row, Image } from 'antd';
import { marketService } from 'services/market';
import { normalizeItem } from 'utils/converter';
import { BoxCreator, BoxPrice, BoxDescription } from './components';
import { TabHistorySales, TabHistoryBids, RelatedArtist, RelatedItems, PopupQrCode } from './components';

import QrcodeOutlinedIcon from '@ant-design/icons/QrcodeOutlined';

const ExploreView = () => {
  const classes = useStyles();

  const { id } = useParams();
  const { t } = useTranslation();
  const { id: user, ...profile } = useSelector(({ profile }) => profile);

  const [isOpenQrCode, setIsOpenQrCode] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('history');

  const { data: apiItem } = useQuery(
    ['marketService.getItem', { id }],
    ({ queryKey }) => marketService.getItem({ ...queryKey[1] }),
    { refetchInterval: 1000 * 30 },
  );
  const nftItem = React.useMemo(() => normalizeItem(apiItem, profile), [apiItem, profile]);

  const tabs = (
    nftItem.isAuction ? [{ id: 1, code: 'bids', label: t('Bids'), component: <TabHistoryBids item={nftItem} /> }] : []
  ).concat([{ id: 2, code: 'history', label: t('History'), component: <TabHistorySales /> }]);

  React.useEffect(() => {
    setActiveTab(nftItem.isAuction ? 'bids' : 'history');
  }, [nftItem.isAuction]);

  return (
    <BackgroundColor>
      <Container>
        <Paper className={classes.paper}>
          <Row gutter={24}>
            <Col flex='60%' style={{ minHeight: 480 }} className='flex-center'>
              {nftItem.isVideo ? (
                <CardMedia
                  component='video'
                  src={nftItem.image}
                  style={{ maxHeight: 480, minWidth: 120 }}
                  {...{ controls: true, autoPlay: true, loop: true, muted: true }}
                />
              ) : (
                <Image src={nftItem.image} style={{ maxHeight: 480, minWidth: 120 }} />
              )}

              <IconButton
                size='small'
                style={{ position: 'absolute', left: 12, bottom: 0, backgroundColor: '#0007' }}
                onClick={() => setIsOpenQrCode(true)}
              >
                <QrcodeOutlinedIcon style={{ color: '#fff' }} />
              </IconButton>
            </Col>
            <Col flex='40%' className='flex-column'>
              <Typography component='h1' variant='h4' gutterBottom>
                {nftItem.name}
              </Typography>
              <Typography variant='subtitle2' color='textSecondary'>
                {nftItem.views} {t('views')}
              </Typography>
              <Divider className='my-12' />
              <BoxCreator item={nftItem} />

              <Box flex={1} minHeight={20} />
              <BoxPrice item={nftItem} />
            </Col>

            <Dialog open={isOpenQrCode} maxWidth='xs' onClose={() => setIsOpenQrCode(false)}>
              <PopupQrCode onClose={() => setIsOpenQrCode(false)} />
            </Dialog>
          </Row>
        </Paper>
        <Row gutter={24} className='mt-24'>
          <Col style={{ flex: 3 }}>
            <Paper className={classes.paper}>
              <BoxDescription item={nftItem} />
            </Paper>
          </Col>
          <Col style={{ flex: 2 }}>
            <Paper className={classes.paper}>
              <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
                {tabs.map((tab) => (
                  <Tab key={tab.id} label={tab.label} value={tab.code} style={{ minWidth: 80 }} />
                ))}
              </Tabs>
              {tabs.map((tab) => (
                <Box key={tab.id} hidden={tab.code !== activeTab} py={2}>
                  {tab.component}
                </Box>
              ))}
            </Paper>
          </Col>
        </Row>
      </Container>

      {nftItem.id && <RelatedArtist ignored={id} artist={nftItem.owner.address} />}
      {nftItem.id && user !== nftItem.owner.address && (
        <RelatedItems
          nftID={id}
          ownerAddr={nftItem.owner.address}
          creatorAddr={nftItem.creatorAddr}
          subCategoryID={nftItem.subCategoryID}
        />
      )}
    </BackgroundColor>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    height: '100%',
  },
}));

export default ExploreView;
