import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListItem, ListItemText, Link as NavLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { privateRoute } from 'routes';

const Menu = ({ onClickMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { explore, aboutUs } = privateRoute;

  const MenuItem = ({ visible = true, name, path, onClick }) => {
    const location = useLocation();
    return visible ? (
      <ListItem
        button
        component={Link}
        to={path ?? '/'}
        className={classes.item}
        selected={location.pathname.startsWith(path)}
        onClick={() => {
          if (onClick) onClick();
          else if (onClickMenu) onClickMenu();
        }}
      >
        <ListItemText primary={name} />
      </ListItem>
    ) : null;
  };

  return (
    <>
      <MenuItem {...explore} name={t('Explore')} />
      <ListItem button className={classes.item} component={NavLink} href='https://launchpad.spores.app'>
        <ListItemText primary={t('Launchpad')} />
      </ListItem>
      <div style={{ margin: 4, height: 24, borderRight: '2px solid #0002' }} />
      <MenuItem {...aboutUs} name={t('About us')} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    width: 'unset',
    height: 40,
    margin: '0px 4px',
    padding: '0px 8px',
    backgroundColor: '#fff !important',
    color: theme.palette.action.active,
    '&:hover, &.Mui-selected': {
      color: theme.palette.common.black,

      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 24,
        height: 2,
        backgroundColor: '#242424',
      },
    },

    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap',
    },
  },
}));

export default Menu;
