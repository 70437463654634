import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Typography, Divider, CardMedia, Link as NavLink, Button, Dialog, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Col, Row } from 'antd';
import { color } from 'utils/constants';
import { formatPrice } from 'utils/common';
import { normalizeItem } from 'utils/converter';
import { privateRoute } from 'routes';
import PopupTransaction from './PopupTransaction';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const AddressLine = ({ label, address }) => {
  const classes = useStyles();
  const { chain } = useSelector(({ coreui }) => coreui);

  return (
    <Box className='justify-content-start'>
      <Typography variant='body2' color='textSecondary' className='text-ellipsis'>
        {label}:{' '}
        <Typography variant='subtitle2' component='span'>
          {address}
        </Typography>
      </Typography>
      <NavLink href={chain.viewTrans(address)} className={classes.link + ' align-items-end'}>
        <OpenInNewIcon style={{ fontSize: 18 }} />
      </NavLink>
    </Box>
  );
};

const InfoLine = ({ label, value, action }) => {
  return (
    <Typography variant='body2' color='textSecondary' className='flex-row' gutterBottom noWrap>
      {label}
      {':'}
      <Typography variant='subtitle2' className='text-ellipsis ml-4'>
        {value}
      </Typography>
      {action}
    </Typography>
  );
};

export const InfoBox = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);

  return (
    <Box>
      <Typography variant='h6' gutterBottom>
        <Link to={privateRoute.exploreView.url(item.id)}>{item.name}</Link>
      </Typography>
      <InfoLine
        label={t('Creator')}
        value={
          <Link to={privateRoute.artist.url(item.creator.address)} className={classes.link}>
            {item.creator.name}
          </Link>
        }
      />
      <InfoLine label={t('Collection')} value={item.collection.name} />
      <InfoLine
        label={t('Token ID')}
        value={item.tokenID}
        action={
          <NavLink
            href={chain.viewTokenID(item.nftContractAddr, item.tokenID)}
            className={classes.link + ' align-items-end ml-4'}
          >
            <OpenInNewIcon style={{ fontSize: 18 }} />
          </NavLink>
        }
      />
    </Box>
  );
};

const PriceBox = ({ label, value }) => (
  <Box>
    <Typography variant='body2' color='textSecondary'>
      {label}
    </Typography>
    <Typography variant='overline'>{value}</Typography>
  </Box>
);

const CardTransaction = ({ item: apiItem, isPurchase }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { payments } = useSelector(({ system }) => system);

  const [isOpenDetail, setIsOpenDetail] = React.useState(false);

  const item = React.useMemo(() => {
    const paymentToken = payments.find((next) => next.id === apiItem.paymentTokenId) ?? {};
    const royaltyFee = apiItem.buyedPrice * apiItem.royaltyFee;
    const serviceFee = apiItem.buyedPrice * apiItem.saleFee;
    const totalReceived = apiItem.buyedPrice - (royaltyFee + serviceFee);
    return {
      ...normalizeItem(apiItem),
      paymentToken,
      serviceFee,
      royaltyFee,
      totalReceived,
    };
  }, [apiItem, payments]);

  return (
    <Paper variant='outlined' className={classes.container}>
      <Row>
        <Col md={12} xs={24} className='p-24' style={{ backgroundColor: color.grey }}>
          <CardMedia image={item.isVideo ? item.preview : item.image} className={classes.media} />
          <AddressLine label={t('Transaction')} address={item.txHash} />
        </Col>
        <Col md={12} className='p-24'>
          <InfoBox item={item} />
          <Divider className='my-16' />
          {isPurchase ? (
            <Box minHeight={96}>
              <PriceBox label={t('Price')} value={`${formatPrice(item.buyedPrice)} ${item.paymentToken.symbol}`} />
            </Box>
          ) : (
            <Box minHeight={96}>
              <Box className='justify-content-between align-items-center mb-12'>
                <PriceBox
                  label={t('Selling Price')}
                  value={`${formatPrice(item.buyedPrice)} ${item.paymentToken.symbol}`}
                />
                <Divider orientation='vertical' style={{ height: 24, margin: 8 }} />
                <PriceBox
                  label={t('Total Received')}
                  value={`${formatPrice(item.totalReceived)} ${item.paymentToken.symbol}`}
                />
              </Box>
              <Button variant='outlined' className='px-32' onClick={() => setIsOpenDetail(true)}>
                {t('View')}
              </Button>
              <Dialog open={isOpenDetail} maxWidth='xs' onClose={() => setIsOpenDetail(false)}>
                <PopupTransaction item={item} onClose={() => setIsOpenDetail(false)} />
              </Dialog>
            </Box>
          )}
        </Col>
      </Row>
    </Paper>
  );
};

const CardSkeleton = () => {
  const classes = useStyles();
  return (
    <Paper variant='outlined' className={classes.container}>
      <Row>
        <Col md={12} xs={24} className='p-24' style={{ backgroundColor: color.grey }} />
        <Col md={12} className='p-24'>
          <Skeleton height={34.85} />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Divider className='my-24' />
          <Skeleton />
          <Skeleton />
          <Box height={44} />
        </Col>
      </Row>
    </Paper>
  );
};

CardTransaction.Skeleton = CardSkeleton;

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  media: {
    height: 180,
    borderRadius: 4,
    marginBottom: 12,
  },
  link: {
    color: theme.palette.grey[600],
  },
}));

export default CardTransaction;
