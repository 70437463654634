import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { PerfectScrollbar } from 'components';
import { marketService } from 'services/market';
import { DDMMYYYY__HHMM } from 'utils/constants';
import { moment } from 'utils/common';
import { privateRoute } from 'routes';
import BoxImage from './BoxImage';

const TabHistoryBids = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery(
    ['marketService.fetchHistoryBids', { saleId: item.saleInfo.id }],
    ({ queryKey }) => marketService.fetchHistoryBids({ ...queryKey[1] }),
    { enabled: !!item.saleInfo.id, refetchInterval: 1000 * 60 },
  );
  const dataList = (data.bids ?? []).filter((item) => item.status === 0);

  return dataList.length > 0 ? (
    <PerfectScrollbar style={{ maxHeight: 68 * 4, paddingRight: 12 }}>
      {dataList.map((item) => (
        <Box key={item.id} className={classes.wrapper}>
          <BoxImage
            reverse
            image={item.creator.avatar}
            label={moment(item.createdAt * 1000).format(DDMMYYYY__HHMM)}
            author={
              <div className='justify-content-between'>
                <Link to={privateRoute.artist.url(item.creator.address)} className={classes.link}>
                  {item.creator.name}
                </Link>
                <span>{`${item.offerPrice} ${item.paymentToken.symbol}`}</span>
              </div>
            }
            size={44}
            user={{ address: item.creator.address }}
          />
        </Box>
      ))}
    </PerfectScrollbar>
  ) : (
    <Typography variant='subtitle2' color='textSecondary' align='center' className='my-12'>
      {t('No active bid yet')}
      <br />
      {t('Be the first one to make a bid!')}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 12,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  link: {
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default TabHistoryBids;
